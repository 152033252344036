import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { BottomMenuPage, MobileBottomMenuComponent } from '@modules/mobile-bottom-menu/mobile-bottom-menu.component';
import { LayoutService } from '@services/layout.service';
import { LayoutCartSidebarComponent } from './layout-cart-sidebar/layout-cart-sidebar.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';
import { LayoutTopbarComponent } from './layout-topbar/layout-topbar.component';
import {AiAssistantComponent} from "@modules/ai-assistant/ai-assistant.component";

/**
 * Represents a class that defines the layout properties for a component.
 */
type LayoutClass = {
  [key: string]: boolean;
};

/**
 * Array containing the layout components used in the layout component.
 */
const LAYOUT_COMPONENTS = [
  LayoutSidebarComponent,
  LayoutCartSidebarComponent,
  LayoutTopbarComponent,
  LayoutFooterComponent,
  MobileBottomMenuComponent,
];

/**
 * Represents the layout component of the application.
 */
@Component({
  templateUrl: './layout.component.html',
  standalone: true,
  imports: [LAYOUT_COMPONENTS, NgClass, RouterOutlet, AiAssistantComponent],
})
export class LayoutComponent {
  /**
   * The layout service instance.
   */
  public readonly layoutService = inject(LayoutService);

  /**
   * The router instance.
   */
  public readonly router = inject(Router);

  /**
   * Represents the bottom menu page.
   */
  BottomMenuPage = BottomMenuPage;

  /**
   * Gets the layout class based on the current state.
   * @returns The layout class as a string or LayoutClass.
   */
  get layoutClass(): string | LayoutClass {
    return {
      'sidebar-visible': this.layoutService.layoutState.sidebarActive,
      'sidebar-cart-visible': this.layoutService.layoutState.sidebarCartActive(),
      'is-account-page': this.router.url.includes('my-account'),
      'is-mobile-menu-active': this.layoutService.layoutState.bottomMenuVisible(),
      'is-restaurants-details-page': this.router.url.includes('restaurants/details'),
      'is-lord-jack': this.router.url.includes('restaurants/details/lordjack'),
      'is-configurator': this.router.url.includes('restaurants/alcohol-configurator/lordjack'),
    };
  }
}
