import { Component } from '@angular/core';
import { CompanyConfig } from '@configs/company.config';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';

/**
 * Represents the SocialMediaComponent.
 */
@Component({
  selector: 'app-social-media',
  standalone: true,
  imports: [ButtonModule, SvgIconComponent],
  template: `
    <div class="flex align-items-center gap-3">
      <a
        [href]="socialMediaUrl.Facebook"
        target="_blank"
        pButton
        severity="primary"
        class="social-media-button flex align-items-center justify-content-center">
        <svg-icon src="assets/icons/fb.svg"></svg-icon>
      </a>
      <a
        [href]="socialMediaUrl.Instagram"
        target="_blank"
        pButton
        severity="primary"
        class="social-media-button flex align-items-center justify-content-center">
        <svg-icon src="assets/icons/ig.svg"></svg-icon>
      </a>
      @if (socialMediaUrl.Tiktok) {
        <a
          [href]="socialMediaUrl.Tiktok"
          target="_blank"
          pButton
          severity="primary"
          class="social-media-button flex align-items-center justify-content-center">
          <svg-icon src="assets/icons/tt.svg"></svg-icon>
        </a>
      }
    </div>
  `,
  styleUrl: './social-media.component.scss',
})
export class SocialMediaComponent {
  /**
   * Represents the social media URLs.
   */
  socialMediaUrl = CompanyConfig.SOCIAL_MEDIA;
}
