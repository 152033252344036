import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { StateKey, TransferState, makeStateKey } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Custom TranslateLoader implementation for browser environment.
 * This loader is responsible for loading translations from the server using HttpClient.
 * It also utilizes TransferState to cache translations and avoid unnecessary HTTP requests.
 */
export class TranslateBrowserLoader implements TranslateLoader {
  /**
   * Constructs a new TranslateBrowserLoader instance.
   * @param http The HttpClient instance used for making HTTP requests.
   * @param transferState The TransferState instance used for caching translations.
   */
  constructor(
    private http: HttpClient,
    private transferState: TransferState
  ) {}

  /**
   * Retrieves the translation data for the specified language.
   * @param lang The language code for the desired translation.
   * @returns An Observable that emits the translation data.
   */
  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>('transfer-translate-' + lang);
    const data = this.transferState.get(key, null);

    if (data) {
      return new Observable(observer => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new TranslateHttpLoader(this.http).getTranslation(lang);
    }
  }
}

/**
 * Factory function for creating an instance of TranslateBrowserLoader.
 * @param httpClient The HttpClient instance used for making HTTP requests.
 * @param transferState The TransferState instance used for caching translations.
 * @returns An instance of TranslateBrowserLoader.
 */
export function translateBrowserLoaderFactory(httpClient: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoader(httpClient, transferState);
}
