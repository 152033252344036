import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Languages } from '@configs/languages.config';
import { EventDataType } from '@enums/event-data-type.enum';
import { Langauge } from '@enums/language.enum';
import { TranslateModule } from '@ngx-translate/core';
import { EventBusService } from '@services/event-bus.service';
import { LanguageService } from '@services/language.service';
import { StorageService } from '@services/storage.service';
import { DropdownModule } from 'primeng/dropdown';

/**
 * Array of PrimeNG modules used in the LanguageSelectComponent.
 */
const PRIME_NG_MODULES = [DropdownModule];

/**
 * Component for selecting the language in the application.
 */
@Component({
  selector: 'app-language-select',
  standalone: true,
  imports: [PRIME_NG_MODULES, FormsModule, TranslateModule, CommonModule],
  template: `
    <p-dropdown
      inputId="language-dropdown"
      [options]="Languages"
      [(ngModel)]="selectedLanguage"
      (onChange)="languageService.useLanguage($event.value); onLanguageChange($event.value)"
      optionLabel="name"
      optionValue="value"
      styleClass="w-full"
      placeholder="{{ 'Placeholders.Select' | translate }}">
      <ng-template pTemplate="selectedItem">
        @if (selectedLanguage) {
          <div class="flex align-items-center gap-3">
            <img
              src="assets/images/flag_placeholder.png"
              [class]="'flag flag-' + selectedLanguage"
              style="width: 24px" />
            <div>{{ 'Languages.' + selectedLanguage | translate }}</div>
          </div>
        }
      </ng-template>
      <ng-template let-language pTemplate="item">
        <div class="flex align-items-center gap-3">
          <img src="assets/images/flag_placeholder.png" [class]="'flag flag-' + language.value" style="width: 24px" />
          <div>{{ 'Languages.' + language.value | translate }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  `,
  styleUrl: './language-select.component.scss',
})
export class LanguageSelectComponent {
  /**
   * The storage service used in the language select component.
   */
  public readonly storageService = inject(StorageService);
  /**
   * The language service used for managing language-related functionality.
   */
  public readonly languageService = inject(LanguageService);
  /**
   * The event bus service used for communication between components.
   */
  public readonly eventBusService = inject(EventBusService);

  /**
   * Represents the available languages.
   */
  Languages = Languages;
  /**
   * The selected language for the language select component.
   * It is initialized with the language retrieved from the storage service,
   * or with the default language if no language is found in the storage.
   */
  selectedLanguage: string = this.storageService.getLanguage() ?? Langauge.PL;

  /**
   * Handles the change event when a language is selected.
   * @param language The selected language.
   */
  onLanguageChange(language: string): void {
    this.eventBusService.emit({ name: EventDataType.CHANGE_LOCALE, value: language });
  }
}
