import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents a dynamic import function that loads the NotFoundPageComponent.
 *
 * @returns A Promise that resolves to the NotFoundPageComponent.
 */
const NOT_FOUND_PAGE = () =>
  import('@pages/not-found-page/not-found-page.component').then(c => c.NotFoundPageComponent);

/**
 * Represents the route configuration for the "Not Found" page.
 */
export const NotFoundRoute: Route = {
  path: AppRoutes.NotFound,
  loadComponent: NOT_FOUND_PAGE,
};
