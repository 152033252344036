<div class="layout-footer">
  <div class="layout-footer-gradient"></div>
  <div class="layout-footer-container">
    <div class="layout-footer-content">
      <div class="layout-footer-top">
        <div class="grid">
          <div class="col-12 lg:col-4 xl:col-5">
            <div class="is-expanded layout-footer-about">
              <h6 class="layout-footer-title">{{ 'Footer.About us' | translate }}</h6>
              <div class="layout-footer-expandable-content">
                <p class="layout-footer-about-content">
                  {{ 'Footer.About' | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-2 xl:col-2">
            <!-- <div [class.is-expanded]="expanded(FooterSectionName.BLOG)">
              <h6 class="layout-footer-title" (click)="expand(FooterSectionName.BLOG)">
                {{ 'Footer.Blog' | translate }}
                <i class="pi pi-chevron-down"></i>
              </h6>
              <div class="layout-footer-expandable-content">
                <ul class="list-unstyled">
                  <li>
                    <a href="#" class="link-default">Pizzeria 105</a>
                  </li>
                  <li>
                    <a href="#" class="link-default">Georgia</a>
                  </li>
                  <li>
                    <a href="#" class="link-default">Blue Diamond</a>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <div class="col-12 lg:col-2 xl:col-2">
            <div [class.is-expanded]="expanded(FooterSectionName.COOPERATION)">
              <h6 class="layout-footer-title" (click)="expand(FooterSectionName.COOPERATION)">
                {{ 'Footer.Cooperation' | translate }}
                <i class="pi pi-chevron-down"></i>
              </h6>
              <div class="layout-footer-expandable-content">
                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="[AppRoutes.ForCompanies]" class="link-default">{{
                      'Footer.For companies' | translate
                    }}</a>
                  </li>
                  <li>
                    <a [routerLink]="[AppRoutes.ForRestaurants]" class="link-default">{{
                      'Footer.For restaurants' | translate
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-4 xl:col-3">
            <div [class.is-expanded]="expanded(FooterSectionName.CONTACT)">
              <h6 class="layout-footer-title" (click)="expand(FooterSectionName.CONTACT)">
                {{ 'Footer.Contact' | translate }}
                <i class="pi pi-chevron-down"></i>
              </h6>
              <div class="layout-footer-expandable-content">
                <ul class="list-unstyled">
                  <li>
                    {{ 'Footer.Phone' | translate }}:
                    <a class="link-default" [attr.href]="'tel:' + CompanyConfig.CONTACT.phone">{{
                      CompanyConfig.CONTACT.phone
                    }}</a>
                  </li>
                  <li>
                    {{ 'Footer.Email' | translate }}:
                    <a class="link-default" [attr.href]="'mailto:' + CompanyConfig.CONTACT.email">{{
                      CompanyConfig.CONTACT.email
                    }}</a>
                  </li>
                  <li>
                    <span>{{ 'Footer.Working hours' | translate }} {{ CompanyConfig.GENERAL.workingHours }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mt-4">
              <app-social-media></app-social-media>
            </div>
          </div>
        </div>
      </div>
      <div class="layout-footer-bottom">
        <div class="layout-footer-bottom-copy">{{ 'Footer.Copyrights' | translate }}</div>
        <div class="layout-footer-bottom-links">
          <a [routerLink]="[AppRoutes.Terms]" class="link-default">{{ 'Footer.Terms of use' | translate }}</a>
          <a [routerLink]="[AppRoutes.Privacy]" class="link-default">{{ 'Footer.Privacy policy' | translate }}</a>
          <a [routerLink]="[AppRoutes.LegalInformations]" class="link-default">{{ 'Footer.Legal information' | translate }}</a>
          <a [routerLink]="[AppRoutes.Cookies]" class="link-default">{{ 'Footer.Cookies policy' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
