<ng-template #topbarAddress>
  <app-top-bar-address></app-top-bar-address>
</ng-template>
<ng-template #topbarSearch>
  <app-top-bar-search></app-top-bar-search>
</ng-template>
<ng-template #topbarCart let-iconOnly="iconOnly">
  <app-top-bar-cart [iconOnly]="iconOnly"></app-top-bar-cart>
</ng-template>
<ng-template #layoutCartContent>
  <app-layout-cart-sidebar-content></app-layout-cart-sidebar-content>
</ng-template>

@if (layoutService.layoutState.mobileContentPageVisible()) {
  @switch (layoutService.layoutState.mobileContentPage()) {
    @case (BottomMenuPage.SEARCH) {
      <app-mobile-content-page>
        <div content>
          <ng-container [ngTemplateOutlet]="topbarSearch"></ng-container>
        </div>
      </app-mobile-content-page>
    }
    @case (BottomMenuPage.CART) {
      @if (isMobile()) {
        <app-mobile-content-page>
          <div content>
            <ng-container [ngTemplateOutlet]="layoutCartContent"></ng-container>
          </div>
        </app-mobile-content-page>
      }
    }
  }
}

<div class="layout-topbar">
  <div class="layout-topbar-container">
    <div class="layout-topbar-wrapper">
      <div class="layout-topbar-content flex align-items-center">
        <p-button
          [text]="true"
          [rounded]="true"
          severity="secondary"
          icon="pi pi-bars"
          size="large"
          class="mr-4"
          styleClass="no-shadow"
          (onClick)="layoutService.onSidebarToggle()"></p-button>
        <div class="layout-topbar-logo">
          <a (click)="hideCart()" [routerLink]="resolveRouterLink().routerLink">
            @if (resolveRouterLink().showBackArrow) {
              <i class="pi pi-chevron-left"></i>
            }
            <svg-icon src="assets/images/logo.svg"></svg-icon>
          </a>
        </div>
      </div>
      <div class="layout-topbar-actions">
        @if (isMobile() && layoutService.layoutState.filtersButtonVisible) {
          <p-button
            [text]="true"
            [rounded]="true"
            severity="secondary"
            icon="dropdownicon"
            size="large"
            styleClass="no-shadow"
            (onClick)="openMobileFilters()">
            <ng-template pTemplate="dropdownicon">
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                [ngClass]="{ 'is-active': layoutService.layoutState.mobileContentPage() === BottomMenuPage.FILTERS }">
                <path
                  d="M3.25 1V5.25M3.25 9.5V18M10 1V11.625M10 15.875V18M16.75 1V2.0625M16.75 6.3125V18M1 5.25H5.5V9.5H1V5.25ZM7.75 11.625H12.25V15.875H7.75V11.625ZM14.5 2.0625H19V6.3125H14.5V2.0625Z"
                  stroke="#383A3A"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </ng-template>
          </p-button>
        } @else {
          @if (layoutService.layoutState.topbarAddressVisible) {
            <ng-container [ngTemplateOutlet]="topbarAddress"></ng-container>
          }
          @if (layoutService.layoutState.topbarSearchVisible) {
            <ng-container [ngTemplateOutlet]="topbarSearch"></ng-container>
          }
          @if (layoutService.layoutState.topbarCartButtonVisibility) {
            <ng-container
              [ngTemplateOutlet]="topbarCart"
              [ngTemplateOutletContext]="{ iconOnly: layoutService.layoutState.topbarCartButtonIconOnly }">
            </ng-container>
          }
        }
      </div>
    </div>
  </div>
</div>

@if (!isMobile()) {
  <app-layout-cart-sidebar></app-layout-cart-sidebar>
}
