<div class="layout-cart-sidebar-content">
  <div class="layout-cart-sidebar-header">
    <div class="layout-cart-sidebar-title">{{ 'Cart.Your order' | translate }}</div>
  </div>
  <div class="layout-cart-sidebar-body">
    @if (amountOfMinimalPriceLeft() > 0 && counter() > 0 && isDeliveryAddressSelected()) {
      <div class="layout-cart-sidebar-minimal-price">
        <p-messages severity="warn">
          <ng-template pTemplate>
            <i class="pi pi-dollar"></i>
            <div class="layout-cart-sidebar-minimal-price-alert">
              <div
                class="layout-cart-sidebar-minimal-price-alert-text"
                [innerHTML]="
                  'Cart.Minimal price info' | translate: { price: amountOfMinimalPriceLeft() | customCurrency }
                "></div>
            </div>
          </ng-template>
        </p-messages>
      </div>
    }
    <app-cart-items></app-cart-items>
  </div>

  <div class="layout-cart-sidebar-footer">
    @if (counter() > 0) {
      <p-button
        severity="secondary"
        [label]="('Cart.Go to checkout' | translate) + ': ' + (totalPrice() | customCurrency)"
        (onClick)="goToCheckout()"
        [rounded]="true"
        styleClass="w-full"></p-button>
    }
  </div>
</div>
