import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the base route configuration.
 */
export const BaseRoute: Route = {
  path: AppRoutes.Blank,
  redirectTo: AppRoutes.Homepage,
  pathMatch: 'full',
};
