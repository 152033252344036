import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { ApplicationConfig, TransferState, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { scrollConfig } from '@configs/scroll-restoration.config';
import { environment } from '@env';
import { translateBrowserLoaderFactory } from '@loaders/translate-browser.loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { APP_PROVIDERS } from '@providers/providers';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { routes } from './app.routes';
import { databaseConfig } from './database/database.config';

registerLocaleData(localePl);

/**
 * Application configuration object.
 */
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling(scrollConfig)),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAngularSvgIcon(),
    importProvidersFrom([
      BrowserModule,
      GoogleMapsModule,
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateBrowserLoaderFactory,
          deps: [HttpClient, TransferState],
        },
      }),
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      NgxIndexedDBModule.forRoot(databaseConfig),
    ]),
    APP_PROVIDERS,
  ],
};
