import { DBConfig } from 'ngx-indexed-db';
import { DatabaseTables } from './enums/database-tables.enum';

/**
 * The name of the database.
 */
const DATABASE_NAME = 'ewaiter-db';

/**
 * Configuration object for the database.
 */
export const databaseConfig: DBConfig = {
  name: DATABASE_NAME,
  version: 1,
  objectStoresMeta: [
    {
      store: DatabaseTables.LAST_SEARCH,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'search', keypath: 'search', options: { unique: false } },
        { name: 'userId', keypath: 'userId', options: { unique: false } },
      ],
    },
    {
      store: DatabaseTables.MULTICART,
      storeConfig: { keyPath: 'hostname', autoIncrement: false },
      storeSchema: [
        { name: 'hostname', keypath: 'hostname', options: { unique: true } },
        { name: 'cart', keypath: 'cart', options: { unique: false } },
        { name: 'restaurantName', keypath: 'restaurantName', options: { unique: false } },
        { name: 'minimalPrice', keypath: 'minimalPrice', options: { unique: false } },
        { name: 'email', keypath: 'email', options: { unique: false } },
        { name: 'deliveryMethods', keypath: 'deliveryMethods', options: { unique: false } },
        { name: 'paymentMethods', keypath: 'paymentMethods', options: { unique: false } },
      ],
    },
  ],
  // migrationFactory,
};

export function migrationFactory() {
  return {
    1: (db: IDBDatabase, transaction: IDBTransaction) => {
      const store = transaction.objectStore(DatabaseTables.MULTICART);
      store.createIndex('hostname', 'hostname', { unique: true });
    },
  };
}
