import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AuthorizedUserGuard } from '@guards/authorized-user.guard';
import { VerifyPageGuard } from '@guards/verify-page.guard';

/**
 * Represents a dynamic import function that loads the Sign In Page component.
 *
 * @returns A Promise that resolves to the Sign In Page component.
 */
const SIGN_IN_PAGE = () =>
  import('@pages/auth/pages/sign-in-page/sign-in-page.component').then(m => m.SignInPageComponent);

/**
 * Represents the dynamic import function for the sign-up page component.
 * @returns A Promise that resolves to the module containing the sign-up page component.
 */
const SIGN_UP_PAGE = () =>
  import('@pages/auth/pages/sign-up-page/sign-up-page.component').then(m => m.SignUpPageComponent);

/**
 * Represents a function that dynamically imports the ResetPasswordPageComponent.
 * @returns A Promise that resolves to the ResetPasswordPageComponent module.
 */
const RESET_PASSWORD_PAGE = () =>
  import('@pages/auth/pages/reset-password-page/reset-password-page.component').then(m => m.ResetPasswordPageComponent);

/**
 * Represents a dynamic import function that loads the NewPasswordPageComponent.
 *
 * @returns A Promise that resolves to the NewPasswordPageComponent module.
 */
const NEW_PASSWORD_PAGE = () =>
  import('@pages/auth/pages/new-password-page/new-password-page.component').then(m => m.NewPasswordPageComponent);

/**
 * Represents a function that dynamically imports the VerifyPageComponent.
 * @returns A Promise that resolves to the VerifyPageComponent module.
 */
const VERIFY_PAGE = () =>
  import('@pages/auth/pages/verify-page/verify-page.component').then(m => m.VerifyPageComponent);

/**
 * Represents the authentication route configuration.
 */
export const AuthRoute: Route = {
  path: AppRoutes.Auth,
  children: [
    {
      path: AppRoutes.SignIn,
      canActivate: [AuthorizedUserGuard],
      loadComponent: SIGN_IN_PAGE,
    },
    {
      path: AppRoutes.SignUp,
      loadComponent: SIGN_UP_PAGE,
    },
    {
      path: AppRoutes.ResetPassword,
      loadComponent: RESET_PASSWORD_PAGE,
    },
    {
      path: AppRoutes.NewPassword,
      loadComponent: NEW_PASSWORD_PAGE,
    },
    {
      path: AppRoutes.Verify,
      children: [
        {
          path: AppRoutes.id,
          canActivate: [VerifyPageGuard],
          loadComponent: VERIFY_PAGE,
        },
        { path: AppRoutes.Blank, redirectTo: AppRoutes.Default, pathMatch: 'full' },
      ],
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.SignIn, pathMatch: 'full' },
  ],
};
