import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Lazy loads the ForCompaniesComponent.
 * @returns A promise that resolves to the ForCompaniesComponent.
 */
const FOR_COMPANIES_PAGE = () =>
  import('@pages/for-companies/for-companies.component').then(c => c.ForCompaniesComponent);

/**
 * Represents the route configuration for the "For Companies" page.
 */
export const ForCompaniesRoute: Route = {
  path: AppRoutes.ForCompanies,
  loadComponent: FOR_COMPANIES_PAGE,
};
