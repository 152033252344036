import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Lazy loads the TermsOfServiceComponent.
 * @returns A promise that resolves to the TermsOfServiceComponent.
 */
const TERMS_PAGE = () =>
  import('@pages/terms-of-service/terms-of-service.component').then(c => c.TermsOfServiceComponent);

/**
 * Represents the route configuration for the Terms page.
 */
export const TermsRoute: Route = {
  path: AppRoutes.Terms,
  loadComponent: TERMS_PAGE,
};
