import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the lazy-loaded component for the cart page.
 *
 * @returns A promise that resolves to the `CartComponent`.
 */
const CART_PAGE = () => import('@pages/cart/cart.component').then(c => c.CartComponent);

/**
 * Represents the dynamic import function for the cart details component.
 * @returns A promise that resolves to the cart details component.
 */
const CART_DETAILS_PAGE = () => import('@pages/cart/pages/details/details.component').then(c => c.DetailsComponent);

/**
 * Represents the dynamic import for the cart summary page component.
 * @returns A promise that resolves to the `SummaryComponent` when the import is complete.
 */
const CART_SUMMARY_PAGE = () => import('@pages/cart/pages/summary/summary.component').then(c => c.SummaryComponent);

/**
 * Represents the route configuration for the cart page.
 */
export const CartRoute: Route = {
  path: AppRoutes.Cart,
  loadComponent: CART_PAGE,
  children: [
    {
      path: AppRoutes.CartDetails,
      loadComponent: CART_DETAILS_PAGE,
    },
    {
      path: AppRoutes.CartSummary,
      loadComponent: CART_SUMMARY_PAGE,
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.CartDetails, pathMatch: 'full' },
  ],
};
