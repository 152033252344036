import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the Legal informations page component.
 * @returns A promise that resolves to the LegalInformationsComponent.
 */
const LEGAL_INFORMATIONS_PAGE = () => import('@pages/legal-information/legal-information.component').then(c => c.LegalInformationComponent);

/**
 * Represents the Legal informations route configuration.
 */
export const LegalInformationsRoute: Route = {
  path: AppRoutes.LegalInformations,
  loadComponent: LEGAL_INFORMATIONS_PAGE,
};
