import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PlatformService } from '@services/platform.service';

export const CallbackTpayGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const platform = inject(PlatformService);
  const router = inject(Router);

  if (!platform.isPlatformBrowser()) {
    return false;
  }

  const billId = route.queryParams['billId'];
  const hostname = route.queryParams['hostname'];

  if (billId && hostname) {
    router.navigate([`/${hostname}/cart/summary/${billId}`]);
    return false;
  }

  return true;
};
