import { ChangeDetectionStrategy, Component, ViewChild, inject, model, output } from '@angular/core';
import { AddressSearchLocation } from '@enums/address-search-type.enum';
import { EventDataType } from '@enums/event-data-type.enum';
import { AddressSearchComponent } from '@modules/address-search/address-search.component';
import { EventBusService } from '@services/event-bus.service';
import { PlatformService } from '@services/platform.service';
import { DialogModule } from 'primeng/dialog';

/**
 * Array of PrimeNG modules used in the top-bar-address-dialog component.
 */
const PRIME_NG_MODULES = [DialogModule];

/**
 * Component for the top bar address dialog.
 */
@Component({
  selector: 'app-top-bar-address-dialog',
  standalone: true,
  imports: [PRIME_NG_MODULES, AddressSearchComponent],
  templateUrl: './top-bar-address-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarAddressDialogComponent {
  /**
   * Reference to the AddressSearchComponent.
   */
  @ViewChild(AddressSearchComponent) addressSearchComponent?: AddressSearchComponent;

  /**
   * Event bus service for emitting events.
   */
  private readonly eventBusService = inject(EventBusService);

  /**
   * Platform service for accessing platform-specific functionality.
   */
  readonly platform = inject(PlatformService);

  /**
   * Enum for the address search location.
   */
  AddressSearchLocation = AddressSearchLocation;

  /**
   * Flag indicating whether the dialog is visible.
   */
  isVisible = model<boolean>(false);

  /**
   * Event emitted when the dialog is hidden.
   */
  onHide = output<void>();

  /**
   * Gets the visibility state of the dialog.
   */
  get visible(): boolean {
    return this.isVisible();
  }

  /**
   * Sets the visibility state of the dialog.
   */
  set visible(visible: boolean) {
    this.isVisible.set(visible);
  }

  /**
   * Handles the search event with the specified address.
   * @param address The address to search for.
   */
  onSearch(address: string | null): void {
    this.eventBusService.emit({ name: EventDataType.CHANGE_ADDRESS, value: address });
    this.onHide.emit();
  }

  /**
   * Handles the dialog hide event.
   * Hides the predictions panel and emits the onHide event.
   */
  onDialogHide(): void {
    this.addressSearchComponent?.isPanelOpen.set(false);
    this.onHide.emit();
  }
}
