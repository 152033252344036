import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { GuestGuard } from '@guards/guest.guard';

/**
 * Represents a dynamic import function that loads the RestaurantsListComponent.
 *
 * @returns A Promise that resolves to the RestaurantsListComponent.
 */
const RESTAURANTS_LIST_PAGE = () =>
  import('@pages/restaurants/pages/restaurants-list/restaurants-list.component').then(c => c.RestaurantsListComponent);

/**
 * Represents a dynamic import function that loads the `RestaurantDetailsComponent` component.
 *
 * @returns A Promise that resolves to the `RestaurantDetailsComponent` component.
 */
const RESTAURANT_DETAILS_PAGE = () =>
  import('@pages/restaurants/pages/restaurant-details/restaurant-details.component').then(
    c => c.RestaurantDetailsComponent
  );

const ALCOHOL_CONFIGURATOR_PAGE = () =>
  import('@pages/restaurants/pages/alcohol-configurator/alcohol-configurator.component').then(
    c => c.AlcoholConfiguratorComponent
  );

/**
 * Represents the route configuration for the Restaurants module.
 */
export const RestaurantsRoute: Route = {
  path: AppRoutes.Restaurants,
  canActivate: [GuestGuard],
  children: [
    {
      path: AppRoutes.RestaurantsList,
      loadComponent: RESTAURANTS_LIST_PAGE,
    },
    {
      path: AppRoutes.Restaurant,
      loadComponent: RESTAURANT_DETAILS_PAGE,
    },
    {
      path: AppRoutes.AlcoholConfigurator,
      loadComponent: ALCOHOL_CONFIGURATOR_PAGE,
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.RestaurantsList, pathMatch: 'full' },
  ],
};
