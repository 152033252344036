import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';

/**
 * A guard that checks if the user is authorized to access a route.
 * If the user is already logged in, it redirects to the home page.
 * Otherwise, it allows access to the route.
 *
 * @returns A boolean indicating whether the user is authorized to access the route.
 */
export const AuthorizedUserGuard: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const platform = inject(PlatformService);
  const router = inject(Router);

  if (!platform.isPlatformBrowser()) {
    return false;
  }

  if (storageService.getUser()) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
