import { Component, OnDestroy, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ThemeConfig } from '@configs/theme.config';
import { EventDataType } from '@enums/event-data-type.enum';
import { untilDestroyed } from '@functions/until-destroyed.function';
import { AiAssistantComponent } from '@modules/ai-assistant/ai-assistant.component';
import { EventBusService } from '@services/event-bus.service';
import { LanguageService } from '@services/language.service';
import { StorageService } from '@services/storage.service';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { Subscription } from 'rxjs';
import { PRIME_NG_MODULES } from './primeng.module';

/**
 * Represents the root component of the Angular application.
 * Responsible for setting up the language configuration for PrimeNG API,
 * listening to logout events, and handling the logout functionality.
 */
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, PRIME_NG_MODULES, AiAssistantComponent],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  /**
   * A function that returns an RxJS operator that automatically unsubscribes from observables
   * when the component is destroyed.
   */
  private readonly untilDestroyed = untilDestroyed();
  /**
   * The language service used for handling language-related functionality.
   */
  private readonly languageService = inject(LanguageService);
  /**
   * Configuration for PrimeNG library.
   */
  private readonly primngConfig = inject(PrimeNGConfig);
  /**
   * Service for managing event bus functionality.
   */
  private readonly eventBusService = inject(EventBusService);
  /**
   * Service for managing storage.
   */
  private readonly storageService = inject(StorageService);
  /**
   * The router instance for navigating between routes.
   */
  private readonly router = inject(Router);
  /**
   * Subscription to the event bus.
   */
  private eventBusSub$: Subscription = new Subscription();
  /**
   * Represents the configuration for the theme.
   */
  ThemeConfig = ThemeConfig;

  constructor() {
    this.setupPrimengLanguageConfig();
    this.listenLogoutEvent();
  }

  /**
   * Setup default language and translations for primeng api.
   */
  setupPrimengLanguageConfig(): void {
    this.languageService
      .streamPrimengTranslations()
      .pipe(this.untilDestroyed())
      .subscribe((translation: Translation) => {
        this.primngConfig.setTranslation(translation);
      });
  }

  /**
   * Listening logout event to execute logut method when user has to be logged out
   */
  listenLogoutEvent(): void {
    this.eventBusSub$ = this.eventBusService.on(EventDataType.LOGOUT, () => {
      this.logout();
    });
  }

  /**
   * Automaticly logout user and clean storage data
   */
  logout(): void {
    this.router.navigate(['/']);
    this.storageService.clean();
  }

  /**
   * Destroy lifecycle angular hook
   * Here we are destroyin subscription from any observables
   */
  ngOnDestroy(): void {
    this.eventBusSub$.unsubscribe();
  }
}
