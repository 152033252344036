import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';

/**
 * Represents the dynamic import function for the BillsPageComponent.
 * @returns A Promise that resolves to the BillsPageComponent.
 */
const BILLS_PAGE = () =>
  import('@pages/my-account/pages/bills-page/bills-page.component').then(c => c.BillsPageComponent);

/**
 * Represents a dynamic import function that loads the BillsListPageComponent.
 *
 * @returns A Promise that resolves to the BillsListPageComponent.
 */
const BILLS_LIST_PAGE = () =>
  import('@pages/my-account/pages/bills-page/pages/bills-list-page/bills-list-page.component').then(
    c => c.BillsListPageComponent
  );

/**
 * Represents a dynamic import function that loads the `BillDetailsPageComponent`.
 *
 * @returns A promise that resolves to the `BillDetailsPageComponent`.
 */
const BILL_DETAILS_PAGE = () =>
  import('@pages/my-account/pages/bills-page/pages/bill-details-page/bill-details-page.component').then(
    c => c.BillDetailsPageComponent
  );

/**
 * Represents the route configuration for the bills section of the application.
 */
export const BillsRoute: Route = {
  path: AppRoutes.Bills,
  loadComponent: BILLS_PAGE,
  children: [
    {
      path: AppRoutes.BillsList,
      loadComponent: BILLS_LIST_PAGE,
      data: {
        page: AccountPageType.BILLS,
      },
    },
    {
      path: AppRoutes.BillDetails,
      loadComponent: BILL_DETAILS_PAGE,
      data: {
        page: AccountPageType.BILLS,
      },
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.BillsList, pathMatch: 'full' },
  ],
};
