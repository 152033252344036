import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';

/**
 * Guard that checks if the user is allowed to access a certain page.
 * It verifies if the user to be verified matches the ID parameter in the route.
 * If the user is not allowed, it redirects to the home page.
 *
 * @param route - The activated route snapshot.
 * @returns A boolean indicating whether the user is allowed to access the page.
 */
export const VerifyPageGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const storageService = inject(StorageService);
  const platform = inject(PlatformService);
  const router = inject(Router);

  if (!platform.isPlatformBrowser()) {
    return false;
  }

  if (storageService.getUserToVerify() !== route.paramMap.get('id')) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
