import { Component, inject, signal } from '@angular/core';
import { TopBarAddressDialogComponent } from '@layout/top-bar-address-dialog/top-bar-address-dialog.component';
import { StorageService } from '@services/storage.service';
import { ButtonModule } from 'primeng/button';

/**
 * Represents the top bar address component.
 * This component displays the current address and allows the user to open a dialog to change the address.
 */
@Component({
  selector: 'app-top-bar-address',
  standalone: true,
  imports: [ButtonModule, TopBarAddressDialogComponent],
  template: `
    @if (storageService.getCurrentAddress()) {
      <p-button
        styleClass="p-button-text p-button-secondary"
        icon="pi pi-chevron-down"
        iconPos="right"
        [rounded]="true"
        [text]="true"
        (onClick)="showDialog.set(true)"
        label="{{ storageService.getCurrentAddress() }}"></p-button>
      <app-top-bar-address-dialog
        [isVisible]="showDialog()"
        (onHide)="showDialog.set(false)"></app-top-bar-address-dialog>
    }
  `,
  styleUrl: './top-bar-address.component.scss',
})
export class TopBarAddressComponent {
  /**
   * The storage service instance.
   */
  readonly storageService = inject(StorageService);

  /**
   * A signal to control the visibility of the address dialog.
   */
  showDialog = signal<boolean>(false);
}
