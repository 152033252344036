import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWT } from '@interfaces/jwt.interface';
import { AuthService } from '@services/auth.service';
import { NotificationsService } from '@services/notifications.service';
import { StorageService } from '@services/storage.service';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, tap, throwError } from 'rxjs';

/**
 * Interceptor class that can "refresh" the access token.
 * Refresh token is a credential artifact that lets a client
 * application get new access tokens without having to ask the user to log in again.
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  /**
   * A Subject for checking that refresh token is currently being fetched
   * @param {boolean} false
   */
  private isRefreshing$ = new BehaviorSubject<boolean>(false);

  /**
   * Injection of required dependencies
   *
   * @param {StorageService} storageService Service that we use for getting and updating tokens
   * @param {AuthService} authService Service that we use for getting new tokens
   * @param router
   */
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationsService
  ) {}

  /**
   * Identifies and handles a given HTTP request.
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const access_token = this.storageService.getAccessToken();
    if (access_token) {
      request = this.addTokenHeader(request, access_token);
    }

    return next.handle(request).pipe(
      catchError(errorResponse => {
        if (errorResponse instanceof HttpErrorResponse) {
          const { status } = errorResponse;
          switch (status) {
            case 401:
              this.handle401Exception(request, next);
              break;
            case 403:
              this.handle403Exception();
              break;
            default:
              break;
          }
        }
        return throwError(() => errorResponse);
      })
    );
  }

  /**
   * Handling 401 exception when access token expires, getting new tokens with refresh token
   * @param {HttpRequest} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  private handle401Exception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing$.getValue()) {
      this.storageService.removeAcessToken();
      this.isRefreshing$.next(true);

      const refreshToken = this.storageService.getRefreshToken();

      if (refreshToken) {
        this.authService
          .getRefreshToken(refreshToken)
          .pipe(
            tap((jwt: JWT) => {
              this.storageService.saveJwt(jwt);
              this.isRefreshing$.next(false);
            }),
            switchMap(({ access_token }) => {
              return next.handle(this.addTokenHeader(request, access_token));
            }),
            catchError(error => {
              this.isRefreshing$.next(false);
              return throwError(() => new Error(error));
            })
          )
          .subscribe();
      }

      this.isRefreshing$.next(false);
      return throwError(() => new Error());
    }

    return this.isRefreshing$.pipe(
      filter(is => !is),
      take(1),
      switchMap(() => {
        const accessToken = this.storageService.getAccessToken()!;
        return next.handle(this.addTokenHeader(request, accessToken));
      })
    );
  }

  private async handle403Exception(): Promise<void> {
    await this.router.navigate(['/']);
    this.notificationService.error('Errors.FORBIDDEN');
  }

  /**
   * Addition authorization Berear token to HttpHeaders
   * @param {HttpRequest} request
   * @param {string} token
   * @returns {HttpRequest}
   */
  private addTokenHeader(request: HttpRequest<any>, access_token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + access_token),
    });
  }
}
