import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { CompanyConfig } from '@configs/company.config';
import { FooterSectionName } from '@enums/footer-section-name.enum';
import { FooterSection } from '@interfaces/footer-section.interface';
import { SocialMediaComponent } from '@modules/social-media/social-media.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';

/**
 * Array of PrimeNG modules used in the layout footer component.
 */
const PRIME_NG_MODULES = [ButtonModule];

/**
 * Represents the layout footer component.
 */
@Component({
  selector: 'app-layout-footer',
  standalone: true,
  imports: [PRIME_NG_MODULES, SvgIconComponent, SocialMediaComponent, TranslateModule, RouterLink],
  templateUrl: './layout-footer.component.html',
})
export class LayoutFooterComponent {
  /**
   * Represents the company configuration.
   */
  CompanyConfig = CompanyConfig;

  /**
   * Represents the footer section names.
   */
  FooterSectionName = FooterSectionName;

  /**
   * Represents the available routes in the application.
   */
  AppRoutes = AppRoutes;

  /**
   * Represents the sections in the footer.
   */
  sections: FooterSection[] = [
    {
      expanded: false,
      name: FooterSectionName.ABOUT,
    },
    {
      expanded: false,
      name: FooterSectionName.BLOG,
    },
    {
      expanded: false,
      name: FooterSectionName.COOPERATION,
    },
    {
      expanded: false,
      name: FooterSectionName.CONTACT,
    },
  ];

  /**
   * Toggles the expansion state of a footer section.
   * @param name - The name of the footer section.
   */
  expand(name: FooterSectionName): void {
    const section = this.sections.find(section => section.name === name);
    section!.expanded = !section!.expanded;
  }

  /**
   * Checks if a footer section is expanded.
   * @param name - The name of the footer section.
   * @returns True if the footer section is expanded, false otherwise.
   */
  expanded(name: FooterSectionName): boolean {
    return this.sections.find(section => section.name === name)!.expanded;
  }
}
