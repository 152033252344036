@if (storageService.isTesterUser()) {
  @if (platform.isPlatformBrowser()) {
    <p-dialog
      [modal]="true"
      (onShow)="sendMessageToIframeWindow()"
      [dismissableMask]="true"
      transitionOptions="0s"
      [(visible)]="visible"
      styleClass="dialog-assistant"
      [draggable]="false"
      position="bottomright">
      <ng-template pTemplate="headless">
        <div class="assistant-modal">
          <i class="pi pi-times assistant-modal__close-icon" (click)="hideDialog()"></i>
          <iframe #iframe src="https://asystent-ewaiter.cogniforge-dev.pl/" allow="microphone"></iframe>
        </div>
      </ng-template>
    </p-dialog>
  }
  <i class="pi pi-comment assistant-icon hidden-mobile" (click)="toggleDialog()"></i>
}
