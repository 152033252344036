import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { EventDataType } from '@enums/event-data-type.enum';
import { TranslateModule } from '@ngx-translate/core';
import { EventBusService } from '@services/event-bus.service';
import { LayoutService } from '@services/layout.service';
import { StorageService } from '@services/storage.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';

/**
 * Array of PrimeNG modules used in the authorized user panel layout component.
 */
const PRIME_NG_MODULES = [AvatarModule, ButtonModule];

/**
 * Component representing the authorized user panel in the layout.
 */
@Component({
  selector: 'app-layout-authorized-user-panel',
  standalone: true,
  imports: [PRIME_NG_MODULES, TranslateModule, SvgIconComponent, RouterLink],
  templateUrl: './layout-authorized-user-panel.component.html',
  styleUrl: './layout-authorized-user-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutAuthorizedUserPanelComponent {
  readonly storageService = inject(StorageService);
  readonly eventBusService = inject(EventBusService);
  readonly layoutService = inject(LayoutService);
  readonly appRoutes = AppRoutes;

  /**
   * Logs out the user by emitting a logout event.
   */
  logout(): void {
    this.eventBusService.emit({ name: EventDataType.LOGOUT, value: null });
  }
}
