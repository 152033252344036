<div class="layout" [ngClass]="layoutClass">
  <app-layout-sidebar></app-layout-sidebar>
  <div class="layout-container">
    <app-layout-topbar></app-layout-topbar>
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <app-layout-footer></app-layout-footer>
  </div>
  <!-- <app-layout-cart-sidebar></app-layout-cart-sidebar> -->
  <div class="layout-mask" (click)="layoutService.hideAllSidebars()"></div>
  <app-ai-assistant></app-ai-assistant>
</div>
@if (layoutService.layoutState.bottomMenuVisible()) {
  <app-mobile-bottom-menu></app-mobile-bottom-menu>
}
