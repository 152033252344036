import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';

/**
 * A guard that checks if the user is activated before allowing access to a route.
 * If the user is not activated, it redirects to the configuration page to set the phone number.
 * @returns A boolean indicating whether the user is activated or not.
 */
export const ActivatedUserGuard: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const platform = inject(PlatformService);
  const router = inject(Router);

  if (!platform.isPlatformBrowser()) {
    return false;
  }

  if (!storageService.getUser()) {
    router.navigate(['/']);
    return false;
  }

  if (!storageService.isUserActivated()) {
    router.navigate(['/', AppRoutes.Configuration, AppRoutes.ConfirmPhoneNumber]);
    return false;
  }

  return true;
};
