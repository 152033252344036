import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the dynamic import function for the BlogPostComponent.
 * @returns A Promise that resolves to the BlogPostComponent.
 */
const BLOG_POST_PAGE = () => import('@pages/blog-post/blog-post.component').then(c => c.BlogPostComponent);

/**
 * Represents the route configuration for the blog post page.
 */
export const BlogPostRoute: Route = {
  path: AppRoutes.BlogPost,
  loadComponent: BLOG_POST_PAGE,
};
