@if (platform.isPlatformBrowser()) {
  <p-dialog
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '630px' }"
    [draggable]="false"
    [closable]="true"
    [resizable]="false"
    (onHide)="onDialogHide()"
    position="top"
    styleClass="top-bar-address-dialog">
    <app-address-search
      (onSearch)="onSearch($event)"
      [addressSearchLocation]="AddressSearchLocation.TOPBAR"></app-address-search>
  </p-dialog>
}
