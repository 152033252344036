import {
  afterNextRender,
  Component,
  ElementRef, inject,
  ViewChild,
} from '@angular/core';
import { AiAssistantService } from '@modules/ai-assistant/ai-assistant.service';
import { DialogModule } from 'primeng/dialog';
import { PlatformService } from '@services/platform.service';
import { Router } from '@angular/router';
import { EventBusService } from '@services/event-bus.service';
import { EventDataType } from '@enums/event-data-type.enum';
import { StorageService } from '@services/storage.service';
import { AgeRestrictedDialogComponent } from '@modules/age-restricted-dialog/age-restricted-dialog.component';

@Component({
  selector: 'app-ai-assistant',
  standalone: true,
  imports: [
    DialogModule,
    AgeRestrictedDialogComponent,
  ],
  templateUrl: './ai-assistant.component.html',
  styleUrl: './ai-assistant.component.scss',
  providers: [AiAssistantService],
})
export class AiAssistantComponent {
  @ViewChild('iframe') iframe?: ElementRef<HTMLIFrameElement>;
  private readonly aiAssistantService = inject(AiAssistantService);
  private readonly router = inject(Router);
  private readonly eventBusService = inject(EventBusService);
  protected readonly storageService = inject(StorageService);
  readonly platform = inject(PlatformService);


  constructor() {
    afterNextRender(() => {
      window.addEventListener('message', ({ data }) => {
        if(data.bill) {
          this.router.navigate([`/${data.bill.restaurant_hostname}/cart/summary/${data.bill.id}`]);
          this.hideDialog();
        }
      });
    });
    this.listenEventBus();
  }

  sendMessageToIframeWindow() {
      this.iframe?.nativeElement?.addEventListener('load', () => {
        this.iframe?.nativeElement.contentWindow?.postMessage({ user: this.storageService.getUser(), token: this.storageService.getAccessToken() }, '*');
      });
  }

  get visible(): boolean {
    return this.aiAssistantService.aiAssistantDialog();
  }

  set visible(visible: boolean) {
    this.aiAssistantService.aiAssistantDialog.set(visible);
  }

  toggleDialog(): void {
    this.visible = !this.visible;
  }

  hideDialog(): void {
    this.visible = false;
  }



  listenEventBus(): void {
    this.eventBusService.on(EventDataType.OPEN_ASSISTANT, () => {
      this.toggleDialog();
    });
  }

}

