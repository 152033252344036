<div class="authorized-user-panel">
  <a
    class="authorized-user-panel-user"
    [routerLink]="[appRoutes.MyAccount, appRoutes.Settings]"
    (click)="layoutService.onSidebarToggle()">
    <div class="authorized-user-panel-user-avatar">
      <p-avatar size="xlarge">
        <svg-icon src="assets/icons/avatar.svg"></svg-icon>
      </p-avatar>
    </div>
    <div class="authorized-user-panel-user-info">
      <div class="authorized-user-panel-user-name">
        @if (storageService.getUser()?.fullName; as fullName) {
          {{ fullName }}
        } @else {
          {{ storageService.getUser()?.email }}
        }
      </div>
      <span class="authorized-user-panel-user-link">{{ 'Sidebar.Account settings' | translate }}</span>
    </div>
  </a>
  <div class="authorized-user-panel-action">
    <p-button label="{{ 'Sidebar.Sign out' | translate }}" styleClass="w-full" (onClick)="logout()"></p-button>
  </div>
</div>
