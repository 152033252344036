import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents a dynamic import function that loads the `BlogComponent` from the '@pages/blog' module.
 *
 * @returns A Promise that resolves to the `BlogComponent`.
 */
const BLOG_PAGE = () => import('@pages/blog/blog.component').then(c => c.BlogComponent);

/**
 * Represents the route configuration for the blog page.
 */
export const BlogRoute: Route = {
  path: AppRoutes.Blog,
  loadComponent: BLOG_PAGE,
};
