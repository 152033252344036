import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents a dynamic import function that loads the `HomePageComponent`.
 * @returns A promise that resolves to the `HomePageComponent`.
 */
const HOME_PAGE = () => import('@pages/home-page/home-page.component').then(c => c.HomePageComponent);

/**
 * Represents the route configuration for the homepage.
 */
export const HomepageRoute: Route = {
  path: AppRoutes.Homepage,
  loadComponent: HOME_PAGE,
};
