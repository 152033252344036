import { inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

/**
 * Returns a function that configures the PrimeNG library.
 * The configuration sets the ripple effect to true and sets the show and hide transition options to '0s'.
 *
 * @returns A promise that resolves when the configuration is complete.
 */
export const primeNgConfigProvider = () => {
  const primeConfig = inject(PrimeNGConfig);

  primeConfig.ripple = true;
  primeConfig.overlayOptions.showTransitionOptions = '0s';
  primeConfig.overlayOptions.hideTransitionOptions = '0s';

  return () => Promise.resolve();
};
