import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, HostListener, OnInit, afterRender, inject, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { untilDestroyed } from '@functions/until-destroyed.function';
import { TopBarLink } from '@interfaces/top-bar-link.interface';
import { LayoutCartSidebarContentComponent } from '@layout/layout-cart-sidebar-content/layout-cart-sidebar-content.component';
import { LayoutCartSidebarComponent } from '@layout/layout-cart-sidebar/layout-cart-sidebar.component';
import { TopBarAddressComponent } from '@layout/top-bar-address/top-bar-address.component';
import { TopBarCartComponent } from '@layout/top-bar-cart/top-bar-cart.component';
import { TopBarSearchComponent } from '@layout/top-bar-search/top-bar-search.component';
import { CartItemsComponent } from '@modules/cart-items/cart-items.component';
import { BottomMenuPage } from '@modules/mobile-bottom-menu/mobile-bottom-menu.component';
import { MobileContentPageComponent } from '@modules/mobile-content-page/mobile-content-page.component';
import { LayoutService } from '@services/layout.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { Subject } from 'rxjs';
import { EventBusService } from '@services/event-bus.service';
import { EventDataType } from '@enums/event-data-type.enum';

/**
 * Array of PrimeNG modules used in the layout topbar component.
 */
const PRIME_NG_MODULES = [ButtonModule];

/**
 * Represents the top bar component of the layout.
 */
@Component({
  selector: 'app-layout-topbar',
  standalone: true,
  imports: [
    PRIME_NG_MODULES,
    SvgIconComponent,
    RouterModule,
    TopBarSearchComponent,
    TopBarCartComponent,
    TopBarAddressComponent,
    MobileContentPageComponent,
    NgTemplateOutlet,
    NgClass,
    CartItemsComponent,
    LayoutCartSidebarComponent,
    LayoutCartSidebarContentComponent,
  ],
  templateUrl: './layout-topbar.component.html',
})
export class LayoutTopbarComponent implements OnInit {
  /**
   * A helper function that creates an RxJS operator which automatically unsubscribes from observables
   * when the component is destroyed.
   */
  private readonly untilDestroyed = untilDestroyed();
  /**
   * The layout service instance.
   */
  public readonly layoutService = inject(LayoutService);
  /**
   * The router instance for navigating between routes.
   */
  private readonly router = inject(Router);

  private readonly eventBusService = inject(EventBusService);

  /**
   * Represents a subject that emits a number whenever a resize event occurs.
   */
  resize$ = new Subject<number>();
  /**
   * Indicates whether the layout is being viewed on a mobile device.
   */
  isMobile = signal<boolean>(false);
  isCartOpen = signal<boolean>(false);

  /**
   * Event handler for the window resize event.
   * @param event - The resize event object.
   */
  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.resize$.next(window.innerWidth);
  }

  constructor() {
    afterRender(() => {
      this.resize$.next(window.innerWidth);
      this.eventBusService.on(EventDataType.OPEN_MOBILE_CART_PAGE, (value: BottomMenuPage) => {
        this.isCartOpen.set(value === BottomMenuPage.CART);
      });
    });
  }

  /**
   * Represents the bottom menu page.
   */
  BottomMenuPage = BottomMenuPage;

  /**
   * Initializes the component.
   * This method is called after the component has been created and initialized.
   */
  ngOnInit(): void {
    this.listenResizeEvent();
    const savedFilters = sessionStorage.getItem('lastFilters');
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.router.navigateByUrl(filters);
    }
    this.resolveRouterLink();
  }

  /**
   * Listens to the resize event and updates the `isMobile` property accordingly.
   */
  listenResizeEvent(): void {
    this.resize$.pipe(this.untilDestroyed()).subscribe((width: number) => {
      this.isMobile.set(width <= 991);
    });
  }

  /**
   * Opens the mobile filters.
   * This method hides the mobile bottom menu and opens the mobile content page for filters.
   */
  openMobileFilters(): void {
    this.layoutService.displayMobileBottomMenu(false);
    this.layoutService.openMobileContentPage(BottomMenuPage.FILTERS);
  }

  /**
   * Resolves the router link and determines whether to show the back arrow.
   * @returns An object containing the routerLink array and a boolean indicating whether to show the back arrow.
   */
  resolveRouterLink(): TopBarLink {
    const url = this.router.url;
    let routerLink: string[];
    let showBackArrow = false;

    if (url.includes('restaurants/details')) {
      routerLink = ['/restaurants/list'];
      showBackArrow = true;
    } else if (url.includes('restaurants/list')) {
      routerLink = ['/'];
      showBackArrow = true;
    } else if (url.includes('cart/details')) {
      const restaurantId = url.split('/')[1];
      routerLink = [`/restaurants/details/${restaurantId}`];
      showBackArrow = true;
    } else {
      routerLink = ['/'];
    }
    sessionStorage.setItem('lastFilters', JSON.stringify(this.router.url));
    return { routerLink, showBackArrow };
  }

  hideCart(): void {
    if (this.isCartOpen()) {
      this.layoutService.closeMobileContentPage();
    }
  }

}
