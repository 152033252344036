import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the privacy page component.
 * @returns A promise that resolves to the PrivacyPolicyComponent.
 */
const PRIVACY_PAGE = () => import('@pages/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent);

/**
 * Represents the privacy route configuration.
 */
export const PrivacyRoute: Route = {
  path: AppRoutes.Privacy,
  loadComponent: PRIVACY_PAGE,
};
