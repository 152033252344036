import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';

/**
 * A dynamic import function that loads the PointsPageComponent.
 *
 * @returns A Promise that resolves to the PointsPageComponent.
 */
const POINTS_PAGE = () =>
  import('@pages/my-account/pages/points-page/points-page.component').then(c => c.PointsPageComponent);

/**
 * Represents the route configuration for the Points page.
 */
export const PointsRoute: Route = {
  path: AppRoutes.Points,
  loadComponent: POINTS_PAGE,
  data: {
    page: AccountPageType.POINTS,
  },
};
