import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';

/**
 * Represents the settings page component.
 * @returns A promise that resolves to the `SettingsPageComponent`.
 */
const SETTINGS_PAGE = () =>
  import('@pages/my-account/pages/settings-page/settings-page.component').then(c => c.SettingsPageComponent);

/**
 * Represents the route configuration for the settings page.
 */
export const SettingsRoute: Route = {
  path: AppRoutes.Settings,
  loadComponent: SETTINGS_PAGE,
  data: {
    page: AccountPageType.SETTINGS,
  },
};
