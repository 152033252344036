import { inject } from '@angular/core';
import { LanguageService } from '@services/language.service';

/**
 * Provides the application language initialization functionality.
 * @returns A promise that resolves when the language initialization is complete.
 */
export const appLanguageProvider = () => {
  const languageService = inject(LanguageService);
  const initLanguage = languageService.getInitLanguage();
  languageService.useLanguage(initLanguage);

  return () => Promise.resolve();
};
