import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the dynamic import function for the 'ForRestaurantsComponent' component.
 * @returns A Promise that resolves to the 'ForRestaurantsComponent' component.
 */
const FOR_RESTAURANTS_PAGE = () =>
  import('@pages/for-restaurants/for-restaurants.component').then(c => c.ForRestaurantsComponent);

/**
 * Represents the route configuration for the "For Restaurants" page.
 */
export const ForRestaurantsRoute: Route = {
  path: AppRoutes.ForRestaurants,
  loadComponent: FOR_RESTAURANTS_PAGE,
};
