import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';

/**
 * Lazy-loaded component for the reservations page.
 *
 * @returns A promise that resolves to the `ReservationsPageComponent`.
 */
const RESERVATIONS_PAGE = () =>
  import('@pages/my-account/pages/reservations-page/reservations-page.component').then(
    c => c.ReservationsPageComponent
  );

/**
 * Represents a dynamic import function that loads the ReservationsListPageComponent.
 *
 * @returns A promise that resolves to the ReservationsListPageComponent.
 */
const RESERVATIONS_LIST_PAGE = () =>
  import(
    '@pages/my-account/pages/reservations-page/pages/reservations-list-page/reservations-list-page.component'
  ).then(c => c.ReservationsListPageComponent);

/**
 * Represents the route configuration for the Reservations page.
 */
export const ReservationsRoute: Route = {
  path: AppRoutes.Reservations,
  loadComponent: RESERVATIONS_PAGE,
  data: {
    page: AccountPageType.RESERVATIONS,
  },
  children: [
    {
      path: AppRoutes.ReservationsList,
      loadComponent: RESERVATIONS_LIST_PAGE,
      data: {
        page: AccountPageType.RESERVATIONS,
      },
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.ReservationsList, pathMatch: 'full' },
  ],
};
