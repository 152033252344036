import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';
import { ActivatedUserGuard } from '@guards/activated-user.guard';

/**
 * A dynamic import function that loads the FriendsPageComponent from the 'friends-page' module.
 *
 * @returns A Promise that resolves to the FriendsPageComponent.
 */
const FRIENDS_PAGE = () =>
  import('@pages/my-account/pages/friends-page/friends-page.component').then(c => c.FriendsPageComponent);

/**
 * Represents the route configuration for the Friends page.
 */
export const FriendsRoute: Route = {
  path: AppRoutes.Friends,
  loadComponent: FRIENDS_PAGE,
  data: {
    page: AccountPageType.FRIENDS,
  },
  canActivate: [ActivatedUserGuard],
};
