import { bootstrapApplication } from '@angular/platform-browser';
import { register } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
// register Swiper custom elements
register();
/**
 * Entry point of the application.
 *
 * @remarks
 * This file is responsible for bootstrapping the Angular application.
 * It imports the necessary modules and components, and calls the `bootstrapApplication` function.
 *
 * @param {typeof AppComponent} component - The root component of the application.
 * @param {any} config - The configuration object for the application.
 *
 * @returns {Promise<void>} A promise that resolves when the application is successfully bootstrapped.
 *
 * @throws {Error} If an error occurs during the bootstrap process.
 */
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
