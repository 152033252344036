import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { ActivatedUserGuard } from '@guards/activated-user.guard';
import { AddressesRoute } from './addresses.route';
import { BillsRoute } from './bills.routes';
import { FriendsRoute } from './friends.route';
import { PointsRoute } from './points.route';
import { ReservationsRoute } from './reservations.route';
import { SettingsRoute } from './settings.route';

/**
 * Represents the dynamic import function for the MyAccountComponent.
 * @returns A Promise that resolves to the MyAccountComponent.
 */
const MY_ACCOUNT_PAGE = () => import('@pages/my-account/my-account.component').then(c => c.MyAccountComponent);

/**
 * Represents the route configuration for the My Account page.
 */
export const MyAccountRoute: Route = {
  path: AppRoutes.MyAccount,
  loadComponent: MY_ACCOUNT_PAGE,
  canActivate: [ActivatedUserGuard],
  children: [
    SettingsRoute,
    BillsRoute,
    ReservationsRoute,
    FriendsRoute,
    AddressesRoute,
    PointsRoute,
    { path: AppRoutes.Blank, redirectTo: AppRoutes.Settings, pathMatch: 'full' },
  ],
};
