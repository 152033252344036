<div class="layout-sidebar">
  <div class="layout-sidebar-content">
    <div class="layout-sidebar-top">
      @if (storageService.isLoggedIn()) {
        <app-layout-authorized-user-panel></app-layout-authorized-user-panel>
      } @else {
        <p-button
          (onClick)="layoutService.onSidebarToggle()"
          label="{{ 'Sidebar.Sign in' | translate }}"
          styleClass="w-full mb-3"
          [routerLink]="[routes.Auth, routes.SignIn]"></p-button>
        <div class="layout-sidebar-register text-center">
          {{ 'Sidebar.You do not have an account' | translate }}
          <a
            [routerLink]="[routes.Auth, routes.SignUp]"
            (click)="layoutService.onSidebarToggle()"
            class="layout-sidebar-register-link"
            >{{ 'Sidebar.Sign up' | translate }}</a
          >
        </div>
      }
      <div class="layout-sidebar-language">
        <app-language-select></app-language-select>
      </div>
      <div class="layout-sidebar-links">
        <ul class="list-unstyled">
          <li>
            <a [routerLink]="['/', AppRoutes.ForCompanies]" (click)="layoutService.onSidebarToggle()">{{
              'Sidebar.For companies' | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['/', AppRoutes.ForRestaurants]" (click)="layoutService.onSidebarToggle()">{{
              'Sidebar.For restaurants' | translate
            }}</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/', AppRoutes.Blog]" (click)="layoutService.onSidebarToggle()">{{
              'Sidebar.Blog' | translate
            }}</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="layout-sidebar-bottom">
      <div class="layout-sidebar-bottom-title mb-4">{{ 'Sidebar.Want to explore more options' | translate }}</div>
      <p-button label="Google Play" styleClass="w-full mb-2" (onClick)="openStore(appLinks.Google)"></p-button>
      <p-button label="App store" styleClass="w-full" (onClick)="openStore(appLinks.Apple)"></p-button>
      <div class="layout-sidebar-divider"></div>
      <div class="layout-sidebar-bottom-socials">
        <app-social-media></app-social-media>
        <svg-icon src="assets/images/logo.svg"></svg-icon>
      </div>
    </div>
  </div>
</div>
