import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents the Cookies policy page component.
 * @returns A promise that resolves to the CookiesPolicyComponent.
 */
const COOKIES_PAGE = () => import('@pages/cookies-policy/cookies-policy.component').then(c => c.CookiesPolicyComponent);

/**
 * Represents the cookies route configuration.
 */
export const CookiesPolicyRoute: Route = {
  path: AppRoutes.Cookies,
  loadComponent: COOKIES_PAGE,
};
