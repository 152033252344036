import { Component } from '@angular/core';
import { LayoutCartSidebarContentComponent } from '@layout/layout-cart-sidebar-content/layout-cart-sidebar-content.component';

/**
 * Represents the layout sidebar component.
 */
@Component({
  selector: 'app-layout-cart-sidebar',
  standalone: true,
  imports: [LayoutCartSidebarContentComponent],
  templateUrl: './layout-cart-sidebar.component.html',
})
export class LayoutCartSidebarComponent {}
