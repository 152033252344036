import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, Provider } from '@angular/core';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { CompanyConfig } from '@configs/company.config';
import { environment } from '@env';
import { RefreshTokenInterceptor } from '@interceptors/refresh-token.interceptor';
import { LanguageService } from '@services/language.service';
import { WINDOW_PROVIDERS } from '@services/window.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { appLanguageProvider } from './app-language.provider';
import { primeNgConfigProvider } from './prime-ng-config.provider';

/**
 * Array of providers used in the application.
 * These providers are responsible for configuring various services and dependencies.
 */
export const APP_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: appLanguageProvider,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: primeNgConfigProvider,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
  {
    provide: LOCALE_ID,
    deps: [LanguageService],
    useFactory: (languageService: LanguageService): string => {
      return languageService.getCurrentLanguage();
    },
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: CompanyConfig.GENERAL.defaultCurrencyCode,
  },
  {
    provide: APP_ID,
    useValue: 'e-waiter-app',
  },
  MessageService,
  ConfirmationService,
  WINDOW_PROVIDERS,
];
