import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { AccountPageType } from '@enums/account-page-type.enum';

/**
 * Represents a dynamic import function that loads the AddressesPageComponent.
 *
 * @returns A Promise that resolves to the AddressesPageComponent.
 */
const ADDRESSES_PAGE = () =>
  import('@pages/my-account/pages/addresses-page/addresses-page.component').then(c => c.AddressesPageComponent);

/**
 * Lazy-loaded component for the addresses list page.
 * @returns A promise that resolves to the AddressesListComponent.
 */
const ADDRESSES_LIST_PAGE = () =>
  import('@pages/my-account/pages/addresses-page/pages/addresses-list/addresses-list.component').then(
    c => c.AddressesListComponent
  );

/**
 * A dynamic import function that loads the AddressesAddComponent from the addresses-add module.
 *
 * @returns A Promise that resolves to the AddressesAddComponent.
 */
const ADDRESSES_ADD_PAGE = () =>
  import('@pages/my-account/pages/addresses-page/pages/addresses-add/addresses-add.component').then(
    c => c.AddressesAddComponent
  );

/**
 * Represents a dynamic import function for the AddressesEditComponent.
 * @returns A Promise that resolves to the AddressesEditComponent.
 */
const ADDRESSES_EDIT_PAGE = () =>
  import('@pages/my-account/pages/addresses-page/pages/addresses-edit/addresses-edit.component').then(
    c => c.AddressesEditComponent
  );

/**
 * Represents the route configuration for the addresses feature.
 */
export const AddressesRoute: Route = {
  path: AppRoutes.Addresses,
  loadComponent: ADDRESSES_PAGE,
  data: {
    page: AccountPageType.ADDRESSES,
  },
  children: [
    {
      path: AppRoutes.AddressesList,
      loadComponent: ADDRESSES_LIST_PAGE,
      data: {
        page: AccountPageType.ADDRESSES,
      },
    },
    {
      path: AppRoutes.AddressesAdd,
      loadComponent: ADDRESSES_ADD_PAGE,
      data: {
        page: AccountPageType.ADDRESSES,
      },
    },
    {
      path: AppRoutes.AddressesEdit,
      loadComponent: ADDRESSES_EDIT_PAGE,
      data: {
        page: AccountPageType.ADDRESSES,
      },
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.AddressesList, pathMatch: 'full' },
  ],
};
