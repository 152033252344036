import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { CallbackTpayGuard } from '@guards/calback-tpay.guard';
import { LayoutComponent } from '@layout/layout.component';
import { AuthRoute } from './routes/auth.route';
import { BaseRoute } from './routes/base.route';
import { BlogPostRoute } from './routes/blog-post.route';
import { BlogRoute } from './routes/blog.route';
import { CartRoute } from './routes/cart.route';
import { ConfigurationRoute } from './routes/configuration.route';
import { CookiesPolicyRoute } from './routes/cookies.route';
import { ForCompaniesRoute } from './routes/for-companies.route';
import { ForRestaurantsRoute } from './routes/for-restaurants.route';
import { HomepageRoute } from './routes/homepage.route';
import { LegalInformationsRoute } from './routes/legal-informations.route';
import { MyAccountRoute } from './routes/my-account.route';
import { NotFoundRoute } from './routes/not-found.route';
import { PrivacyRoute } from './routes/privacy.route';
import { RestaurantsRoute } from './routes/restaurants.route';
import { TermsRoute } from './routes/terms.route';

/**
 * The routes configuration for the application.
 */
export const routes: Routes = [
  {
    path: AppRoutes.Blank,
    component: LayoutComponent,
    children: [
      BaseRoute,
      HomepageRoute,
      AuthRoute,
      RestaurantsRoute,
      MyAccountRoute,
      ConfigurationRoute,
      CartRoute,
      TermsRoute,
      PrivacyRoute,
      NotFoundRoute,
      ForCompaniesRoute,
      ForRestaurantsRoute,
      BlogRoute,
      BlogPostRoute,
      CookiesPolicyRoute,
      LegalInformationsRoute,
    ],
  },
  { path: AppRoutes.CallbackTpay, canActivate: [CallbackTpayGuard], component: LayoutComponent },
  { path: AppRoutes.Wildcard, redirectTo: AppRoutes.NotFound },
];
