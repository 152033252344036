import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { CompanyConfig } from '@configs/company.config';
import { openUrl } from '@functions/open-url.function';
import { LayoutAuthorizedUserPanelComponent } from '@layout/layout-authorized-user-panel/layout-authorized-user-panel.component';
import { LanguageSelectComponent } from '@modules/language-select/language-select.component';
import { SocialMediaComponent } from '@modules/social-media/social-media.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutService } from '@services/layout.service';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';

/**
 * Array of PrimeNG modules used in the layout sidebar component.
 */
const PRIME_NG_MODULES = [ButtonModule];

/**
 * Represents the layout sidebar component.
 */
@Component({
  selector: 'app-layout-sidebar',
  standalone: true,
  imports: [
    PRIME_NG_MODULES,
    SocialMediaComponent,
    LanguageSelectComponent,
    TranslateModule,
    RouterLink,
    LayoutAuthorizedUserPanelComponent,
    SvgIconComponent,
  ],
  templateUrl: './layout-sidebar.component.html',
})
export class LayoutSidebarComponent {
  /**
   * The layout service used by the layout sidebar component.
   */
  public readonly layoutService = inject(LayoutService);
  /**
   * Represents the platform service used in the layout sidebar component.
   */
  private readonly platform = inject(PlatformService);
  /**
   * The storage service used in the layout sidebar component.
   */
  readonly storageService = inject(StorageService);
  /**
   * The links for the application.
   */
  readonly appLinks = CompanyConfig.APP_LINKS;
  /**
   * The routes for the layout sidebar component.
   */
  readonly routes = AppRoutes;
  /**
   * Represents the available routes in the application.
   */
  readonly AppRoutes = AppRoutes;

  /**
   * Opens the store URL in a new tab.
   * @param url - The URL of the store.
   */
  openStore(url: string): void {
    openUrl(url, this.platform);
  }
}
