import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { SignInResponse } from '@interfaces/responses/sign-in-response.interface';
import { User } from '@models/user.model';
import { AuthService } from '@services/auth.service';
import { ErrorService } from '@services/error.service';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';
import { Observable, Subscriber } from 'rxjs';

/**
 * Guard that allows access to routes for guests (unauthenticated users).
 * If the user is not authenticated, it attempts to sign in as a guest and saves the JWT token.
 * If the sign-in is successful, it allows access to the route. Otherwise, it throws an error and denies access.
 *
 * @returns A boolean value indicating whether the guest is allowed to access the route or not.
 */
export const GuestGuard: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const errorService = inject(ErrorService);
  const authService = inject(AuthService);
  const platform = inject(PlatformService);

  if (!platform.isPlatformBrowser()) {
    return false;
  }

  if (storageService.getAccessToken()) return true;

  return new Observable<boolean | UrlTree>((subscriber: Subscriber<boolean | UrlTree>) => {
    authService.guestSignIn().subscribe({
      next: (response: SignInResponse) => {
        storageService.saveJwt(response);
        storageService.saveGuestUser(new User(response.user));
        subscriber.next(true);
      },
      error: (response: HttpErrorResponse) => {
        errorService.throwError(response);
        subscriber.next(false);
      },
    });
  });
};
