import { Route } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';

/**
 * Represents a dynamic import function that loads the ConfigurationPageComponent.
 *
 * @returns A Promise that resolves to the ConfigurationPageComponent.
 */
const CONFIGURATION_PAGE = () =>
  import('@pages/configuration-page/configuration-page.component').then(c => c.ConfigurationPageComponent);

/**
 * Represents a function that dynamically imports the `SetPhoneNumberPageComponent` from the specified path.
 * @returns A Promise that resolves to the `SetPhoneNumberPageComponent`.
 */
const SET_PHONE_NUMBER_PAGE = () =>
  import('@pages/configuration-page/pages/set-phone-number-page/set-phone-number-page.component').then(
    c => c.SetPhoneNumberPageComponent
  );

/**
 * Represents a function that dynamically imports the ConfirmPhoneNumberPageComponent.
 * @returns A Promise that resolves to the ConfirmPhoneNumberPageComponent.
 */
const CONFIRM_PHONE_NUMBER_PAGE = () =>
  import('@pages/configuration-page/pages/confirm-phone-number-page/confirm-phone-number-page.component').then(
    c => c.ConfirmPhoneNumberPageComponent
  );

/**
 * Represents the configuration route.
 */
export const ConfigurationRoute: Route = {
  path: AppRoutes.Configuration,
  loadComponent: CONFIGURATION_PAGE,
  children: [
    {
      path: AppRoutes.SetPhoneNumber,
      loadComponent: SET_PHONE_NUMBER_PAGE,
    },
    {
      path: AppRoutes.ConfirmPhoneNumber,
      loadComponent: CONFIRM_PHONE_NUMBER_PAGE,
    },
    { path: AppRoutes.Blank, redirectTo: AppRoutes.SetPhoneNumber, pathMatch: 'full' },
  ],
};
