import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { LayoutService } from '@services/layout.service';
import { ButtonModule } from 'primeng/button';

/**
 * Represents the top bar cart component.
 */
@Component({
  selector: 'app-top-bar-cart',
  standalone: true,
  imports: [ButtonModule, TranslateModule],
  template: `
    <p-button
      class="cart-button"
      iconPos="right"
      severity="secondary"
      [badge]="counter() > 0 && !syncing() ? counter().toString() : undefined"
      icon="pi pi-shopping-cart"
      [class.label-visible]="!iconOnly()"
      [label]="iconOnly() ? '' : ('Cart.Show cart' | translate)"
      [rounded]="true"
      (onClick)="layoutService.onCartSidebarToggle()"></p-button>
  `,
  styleUrl: './top-bar-cart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarCartComponent {
  /**
   * The cart service used to manage the shopping cart.
   */
  readonly cartService = inject(CartService);
  /**
   * Represents the layout service for the top bar cart component.
   */
  readonly layoutService = inject(LayoutService);

  counter = computed(() => this.cartService.counter());
  syncing = computed(() => this.cartService.syncing());
  /**
   * Indicates whether the cart button should display only the icon or also the label.
   * @param {boolean} value - The value indicating whether to display only the icon.
   */
  iconOnly = input<boolean>(true);
}
