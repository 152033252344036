/**
 * Component for displaying the content of the cart sidebar in the layout.
 */
import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DeliverySettingsType } from '@enums/delivery-settings-type.enum';
import { CartItemsComponent } from '@modules/cart-items/cart-items.component';
import { TranslateModule } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { LayoutService } from '@services/layout.service';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { customCurrency } from '@pipes/custom-currency.pipe';

/**
 * Array of PrimeNG modules used in the layout cart sidebar content component.
 */
const PRIME_NG_MODULES = [ButtonModule, MessagesModule];

@Component({
  selector: 'app-layout-cart-sidebar-content',
  standalone: true,
  imports: [PRIME_NG_MODULES, TranslateModule, CartItemsComponent, CurrencyPipe, RouterModule, customCurrency],
  templateUrl: './layout-cart-sidebar-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutCartSidebarContentComponent {
  /**
   * The cart service instance.
   */
  readonly cartService = inject(CartService);
  /**
   * The layout service used in the cart sidebar content component.
   */
  readonly layoutService = inject(LayoutService);
  /**
   * The router instance for navigating between routes.
   */
  readonly router = inject(Router);

  /**
   * The computed value of the cart counter.
   */
  counter = computed(() => this.cartService.counter());

  /**
   * The computed value of the total price of the cart.
   */
  totalPrice = computed(() => this.cartService.totalPrice());

  /**
   * The computed value of the minimal price required for the cart.
   */
  minimalPrice = computed(() => this.cartService.minimalPrice());

  /**
   * The computed value of the amount of minimal price left to reach the required minimal price.
   */
  amountOfMinimalPriceLeft = computed(() => this.minimalPrice() - this.totalPrice());

  isDeliveryAddressSelected = computed(() => this.cartService.delivery().type === DeliverySettingsType.ADDRESS);

  goToCheckout(): void {
    this.layoutService.onCartSidebarToggle();
    if (this.layoutService.layoutState.sidebarCartActive()) {
      this.layoutService.closeMobileContentPage();
      this.layoutService.layoutState.sidebarCartActive.set(false);
    }

    this.router.navigate(['/' + this.cartService.hostname() + '/cart/details']);
  }
}
